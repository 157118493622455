<template>
    <v-dialog
        persistent
        v-model="uploadDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-stepper v-model="uploadWizard">
                    <v-stepper-header>
                        <v-stepper-step
                            :complete="uploadWizard > 1"
                            step="1"
                        >
                            {{ $t('message.uploadFile') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="uploadWizard > 2"
                            step="2"
                        >
                            {{ $t('message.checkBundles') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="uploadWizard > 3"
                            step="3"
                        >
                            {{ $t('message.addToList') }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="d-flex flex-row pb-3">
                                <v-file-input
                                        :placeholder="$t('message.selectExcelFile')"
                                        :error-messages="manualErrorState"
                                        :rules="excelFile != null ? [...validationRules.validExcelFiles] : [true]"
                                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="excelFile"
                                        @change="checkFile"
                                />
                                <v-btn class="ml-2"
                                       :disabled="excelData === [] && excelFile == null"
                                       :loading="uploadingFile"
                                       @click="parseExcelFile"
                                >
                                    {{ this.excelData.length > 0 ? $t('message.uploaded') : $t('message.upload') }}
                                    <v-icon v-if="this.excelData.length > 0" class="ml-2 success--text">mdi-check</v-icon>
                                </v-btn>
                            </div>
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 2" :disabled="excelData.length === 0">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-simple-table
                                :fixed-header="excelData.length > 20"
                                :height="excelData.length > 20 ? '400px' : null"
                                class="pb-3"
                                dense
                                style="width: 1500px !important;"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="blue-grey lighten-4 pl-1">#</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[0].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[1].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[2].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[3].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[4].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[5].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[6].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[7].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[8].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[9].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[10].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[11].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[12].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[13].text }}</th>
<!--                                            <th class="blue-grey lighten-4 pl-1">{{ headers[14].text }}</th>-->
<!--                                            <th class="blue-grey lighten-4 pl-1">{{ headers[15].text }}</th>-->
<!--                                            <th class="blue-grey lighten-4 pl-1">{{ headers[16].text }}</th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(bundle,index) in excelData" v-bind:key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td :class="bundle.bundleNo.error ? 'red--text' : ''">{{ bundle.bundleNo.text }}</td>
                                            <td :class="bundle.product.error ? 'red--text' : ''">{{ bundle.product.text }}</td>
                                            <td :class="bundle.species.error ? 'red--text' : ''">{{ bundle.species.text }}</td>
                                            <td :class="bundle.certification.error ? 'red--text' : ''">{{ bundle.certification.text }}</td>
                                            <td :class="bundle.grade.error ? 'red--text' : ''">{{ bundle.grade.text }}</td>
                                            <td :class="bundle.spec.error ? 'red--text' : ''">{{ bundle.spec.text }}</td>
                                            <td :class="bundle.mc.error ? 'red--text' : ''">{{ bundle.mc.text }}</td>
                                            <td :class="bundle.thickness.error ? 'red--text' : ''">{{ bundle.thickness.text }}</td>
                                            <td :class="bundle.width.error ? 'red--text' : ''">{{ bundle.width.text }}</td>
                                            <td :class="bundle.length.error ? 'red--text' : ''">{{ bundle.length.text }}</td>
                                            <td :class="bundle.pcs.error ? 'red--text' : ''">{{ bundle.pcs.text }}</td>
                                            <td :class="bundle.quantity.error ? 'red--text' : ''">{{ bundle.quantity.text }}</td>
                                            <td :class="bundle.quantityUnit.error ? 'red--text' : ''">{{ bundle.quantityUnit.text }}</td>
                                            <td :class="bundle.buyingPrice.error ? 'red--text' : ''">{{ bundle.buyingPrice.text }}</td>
<!--                                            <td :class="bundle.importDuty.error ? 'red&#45;&#45;text' : ''">{{ bundle.importDuty.text }}</td>-->
<!--                                            <td :class="bundle.storageCost.error ? 'red&#45;&#45;text' : ''">{{ bundle.storageCost.text }}</td>-->
<!--                                            <td :class="bundle.freightCost.error ? 'red&#45;&#45;text' : ''">{{ bundle.freightCost.text }}</td>-->
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 3">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <div class="pb-3 font-weight-bold">{{ $t('message.add') + " " + excelData.length + " " + $t('message.bundlesToList') + '.' }}</div>
                            <div class="d-flex flex-row pb-3 justify-space-between">
                                <v-btn @click="addToBundleList" :loading="addingToBundleList" color="success">
                                    {{ this.addedToBundleList ? $t('message.added') : $t('message.add') }}
                                    <v-icon v-if="this.addedToBundleList" class="ml-2 white--text">mdi-check</v-icon>
                                </v-btn>
                                <v-btn @click="uploadDialog = false">{{ this.addedToBundleList ? $t('message.close') : $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

const ExcelJS = require('exceljs');

export default {
    name: "BundleExcelUploadV3",
    props: ['stockOrderId','dialog','updateMode'],
    data(){
        return {
            addedToBundleList: false,
            addingToBundleList: false,
            dialogs: {
                error: false,
                error_message: ""
            },
            excelData: [],
            excelFile: null,
            headers: [
                { id: 1, text: this.$t('message.bundleNo') },
                { id: 2, text: this.$t('message.product') },
                { id: 3, text: this.$t('message.species') },
                { id: 4, text: this.$t('message.certification') },
                { id: 5, text: this.$t('message.grade') },
                { id: 6, text: this.$t('message.spec') },
                { id: 7, text: this.$t('message.mc') },
                { id: 8, text: this.$t('message.thickness') },
                { id: 9, text: this.$t('message.width') },
                { id: 10, text: this.$t('message.length') },
                { id: 11, text: this.$t('message.pcs') },
                { id: 12, text: this.$t('message.quantity') },
                { id: 13, text: this.$t('message.unit') },
                { id: 14, text: this.$t('message.buyingPrice') },
                // { id: 15, text: this.$t('message.freightCost') },
                // { id: 16, text: this.$t('message.importDuty') }, //calculated
                // { id: 17, text: this.$t('message.storageCost') }, //calculated
            ],
            loading: {
                add: false,
                get: false,
                save: false
            },
            manualErrorState: '',
            rules: {
                file: [
                    v => !!v || this.$t('message.required'),
                    v => (v && v.size > 0) || this.$t('message.required'),
                ],
            },
            upload_dialog: false,
            uploadingFile: false,
            uploadWizard: 1
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('stock',{
            Bundles: 'Bundles'
        }, 'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('stock',{
            defaultBundle: 'defaultBundle'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'offices',
            'uofmDimensions',
            'uofmQty',
            'validationRules'
        ]),
        uploadDialog: {
            get() {
                return this.upload_dialog;
            },
            set(value){
                this.upload_dialog = value
                if(value === false) {
                    this.uploadWizard = 1
                    this.addingToBundleList = false;
                    this.addedToBundleList = false;
                    this.excelFile = null
                    this.excelData = []
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        ...mapActions('certification',{
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        addToBundleList() {
            this.addingToBundleList = true
            let i = 1
            if(this.Bundles.length > 0){
                i = this.Bundles.length + 1
            }
            let newBundles = [];
            try {
                this.excelData.forEach(row => {
                    let bundle = _.cloneDeep(this.defaultBundle)
                    bundle.index = i
                    bundle.lineNumber = i
                    bundle.Stock.bundleno = row.bundleNo.value
                    bundle.Stock.certification_id = row.certification.value
                    bundle.Stock.costprice = row.buyingPrice.value
                    // bundle.Stock.forwardingcharge = row.freightCost.value
                    bundle.Stock.grade_id = row.grade.value
                    // bundle.Stock.importduty = row.importDuty.value
                    bundle.Stock.length = row.length.value
                    bundle.Stock.mc_id = row.mc.value
                    bundle.Stock.piece = row.pcs.value
                    bundle.Stock.productgroup_id = row.product.value
                    bundle.Stock.spec_id = row.spec.value
                    bundle.Stock.species_id = row.species.value
                    bundle.Stock.thickness_id = row.thickness.value
                    bundle.Stock.volume = row.quantity.value
                    // bundle.Stock.warehousecost = row.storageCost.value
                    bundle.Stock.width = row.width.value

                    bundle.Product.id = row.product.value
                    bundle.Product.title = row.product.text
                    bundle.Species.id = row.species.value
                    bundle.Species.title = row.species.text
                    bundle.Grade.id = row.grade.value
                    bundle.Grade.title = row.grade.text
                    bundle.Spec.id = row.spec.value
                    bundle.Spec.title = row.spec.text
                    bundle.Mc.id = row.mc.value
                    bundle.Mc.title = row.mc.text
                    bundle.Soldmeasurement.id = row.quantityUnit.value
                    bundle.Soldmeasurement.title = row.quantityUnit.text

                    newBundles.unshift(bundle)
                    i++
                })

                if (newBundles.length > 0) {
                    newBundles.reverse();

                    const originalBundles = _.cloneDeep(this.Bundles);

                    this.Bundles = [...originalBundles, ...newBundles]
                }

                if (i === this.Bundles.length + 1) {
                    this.addingToBundleList = false
                    this.addedToBundleList = true
                    this.$toast.success(this.$t('message.successes.bundlesAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline'
                        }
                    )
                } else {
                    this.addingToBundleList = false
                    this.$toast.error(this.$t('message.bundlesCouldNotBeAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } catch(e) {
                this.addingToBundleList = false
                this.$toast.error(this.$t('message.bundlesCouldNotBeAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        checkFile(fileObj) {
            if(fileObj) {
                const allowedExtensions = this.validationRules.validExcelFileExtensions
                if (fileObj.type === "" || allowedExtensions.exec(fileObj.name) == null) {
                    this.manualErrorState = 'XLS or XLSX files only.'
                } else {
                    this.manualErrorState = ''
                }
            }
        },
        continueWizard(nextStep) {
            switch(nextStep){
                case 1:
                    break
                case 2:
                    break
                case 3:
                    break
            }
        },
        async parseExcelFile() {
            let _this = this
            this.uploadingFile = true

            const buffer = await this.readFile(this.excelFile);
            const workbook = new ExcelJS.Workbook();
            const file = await workbook.xlsx.load(buffer);
            const worksheet = await file.worksheets[0];
            try {
                worksheet.eachRow(function (row, rowNumber) {
                    if (rowNumber > 1) {
                        let bundle = {
                            bundleNo: {
                                value: null,
                                text: null,
                                error: false
                            },
                            product: {
                                value: null,
                                text: null,
                                error: false
                            },
                            species: {
                                value: null,
                                text: null,
                                error: false
                            },
                            certification: {
                                value: null,
                                text: null,
                                error: false
                            },
                            grade: {
                                value: null,
                                text: null,
                                error: false
                            },
                            spec: {
                                value: null,
                                text: null,
                                error: false
                            },
                            mc: {
                                value: null,
                                text: null,
                                error: false
                            },
                            thickness: {
                                value: null,
                                text: null,
                                error: false
                            },
                            width: {
                                value: null,
                                text: null,
                                error: false
                            },
                            length: {
                                value: null,
                                text: null,
                                error: false
                            },
                            pcs: {
                                value: null,
                                text: null,
                                error: false
                            },
                            quantity: {
                                value: null,
                                text: null,
                                error: false
                            },
                            quantityUnit: {
                                value: null,
                                text: null,
                                error: false
                            },
                            buyingPrice: {
                                value: null,
                                text: null,
                                error: false
                            },
                            // freightCost: {
                            //     value: null,
                            //     text: null,
                            //     error: false
                            // },
                            // importDuty: {
                            //     value: null,
                            //     text: null,
                            //     error: false
                            // },
                            // storageCost: {
                            //     value: null,
                            //     text: null,
                            //     error: false
                            // }

                        }

                        bundle.bundleNo.value = row.getCell(2).value;
                        bundle.bundleNo.text = row.getCell(2).value ? row.getCell(2).value : _this.$t('message.empty');
                        bundle.bundleNo.error = !row.getCell(2).value;

                        const product = _this.allProductGroups.find(p => p.ProductGroup.title == row.getCell(3).value)
                        bundle.product.value = product ? product.ProductGroup.id : null;
                        bundle.product.text = product ? product.ProductGroup.title : _this.$t('message.empty');
                        bundle.product.error = !product;

                        const species = _this.allSpecies.find(s => s.Species.title == row.getCell(4).value)
                        bundle.species.value = species ? species.Species.id : null;
                        bundle.species.text = species ? species.Species.title : _this.$t('message.empty');
                        bundle.species.error = !species;

                        const certification = _this.allCertifications.find(c => c.Certification.title == row.getCell(5).value)
                        bundle.certification.value = certification ? certification.Certification.id : null;
                        bundle.certification.text = certification ? certification.Certification.title : _this.$t('message.empty');
                        bundle.certification.error = !certification;

                        const grade = _this.allGrades.find(g => g.Grade.title == row.getCell(6).value)
                        bundle.grade.value = grade ? grade.Grade.id : null;
                        bundle.grade.text = grade ? grade.Grade.title : _this.$t('message.empty');
                        bundle.grade.error = !grade;

                        const spec = _this.allCleanedSpecs.find(s => s.Spec.title == row.getCell(7).value)
                        bundle.spec.value = spec ? spec.Spec.id : row.getCell(7).value;
                        bundle.spec.text = spec ? spec.Spec.title : row.getCell(7).value;
                        bundle.spec.error = spec ? false : (row.getCell(7).value == null);

                        const mc = _this.allMoistureContent.find(c => c.Mc.title == row.getCell(8).value)
                        bundle.mc.value = mc ? mc.Mc.id : null;
                        bundle.mc.text = mc ? mc.Mc.title : _this.$t('message.empty');
                        bundle.mc.error = !mc;

                        const thickness = _this.allThickness.find(t => t.Thickness.title == row.getCell(9).value)
                        bundle.thickness.value = thickness ? thickness.Thickness.id : null;
                        bundle.thickness.text = thickness ? thickness.Thickness.title : _this.$t('message.empty');
                        bundle.thickness.error = !thickness;

                        bundle.width.value = row.getCell(10).value;
                        bundle.width.text = row.getCell(10).value ? row.getCell(10).value : _this.$t('message.empty');
                        bundle.width.error = !row.getCell(10).value;

                        bundle.length.value = row.getCell(11).value;
                        bundle.length.text = row.getCell(11).value ? row.getCell(11).value : _this.$t('message.empty');
                        bundle.length.error = !row.getCell(11).value;

                        bundle.pcs.value = row.getCell(12).value;
                        bundle.pcs.text = row.getCell(12).value ? row.getCell(12).value : _this.$t('message.empty');
                        bundle.pcs.error = !row.getCell(12).value;

                        bundle.quantity.value = row.getCell(13).value;
                        bundle.quantity.text = row.getCell(13).value ? row.getCell(13).value : _this.$t('message.empty');
                        bundle.quantity.error = !row.getCell(13).value;

                        const quantityUnit = _this.uofmQty.find(u => u.Measurement.title == row.getCell(14).value)
                        bundle.quantityUnit.value = quantityUnit ? quantityUnit.Measurement.id : null;
                        bundle.quantityUnit.text = quantityUnit ? quantityUnit.Measurement.title : _this.$t('message.empty');
                        bundle.quantityUnit.error = !quantityUnit;

                        bundle.buyingPrice.value = row.getCell(15).value;
                        bundle.buyingPrice.text = row.getCell(15).value ? row.getCell(15).value : _this.$t('message.empty');
                        bundle.buyingPrice.error = !row.getCell(15).value;

                        // bundle.freightCost.value = row.getCell(16).value;
                        // bundle.freightCost.text = row.getCell(16).value ? row.getCell(16).value : _this.$t('message.empty');
                        // bundle.freightCost.error = !row.getCell(16).value;
                        //
                        // bundle.importDuty.value = row.getCell(17).value;
                        // bundle.importDuty.text = row.getCell(17).value ? row.getCell(17).value : _this.$t('message.empty');
                        // bundle.importDuty.error = !row.getCell(17).value;
                        //
                        // bundle.storageCost.value = row.getCell(18).value;
                        // bundle.storageCost.text = row.getCell(18).value ? row.getCell(18).value : _this.$t('message.empty');
                        // bundle.storageCost.error = !row.getCell(18).value;

                        if (row.getCell(2).value != null && row.getCell(2).value !== '') {
                            _this.excelData.push(bundle)
                        }
                    }
                });

            } catch (err) {
                _this.$toast.error(err,
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            _this.uploadingFile = false
        },
        // async parseExcelFile() {
        //     let _this = this
        //     this.uploadingFile = true
        //     let reader = new FileReader()
        //     try {
        //         await reader.readAsArrayBuffer(this.excelFile)
        //         reader.onloadend = async function () {
        //             let file = reader.result;
        //             const workbook = new ExcelJS.Workbook();
        //             try {
        //                 await workbook
        //                     .xlsx
        //                     .load(file)
        //                     .then((workbook) => {
        //                         const worksheet = workbook.worksheets[0]
        //                         try {
        //                             worksheet.eachRow(function (row, rowNumber) {
        //                                 if (rowNumber > 1) {
        //                                     let bundle = {}
        //                                     bundle['bundleNo'] = {
        //                                         value: row.getCell(2).value,
        //                                         text: row.getCell(2).value ? row.getCell(2).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(2).value,
        //                                     }
        //
        //                                     let product = _this.allProductGroups.find(p => p.ProductGroup.title == row.getCell(3).value)
        //                                     bundle['product'] = {
        //                                         value: product ? product.ProductGroup.id : null,
        //                                         text: product ? product.ProductGroup.title : _this.$t('message.empty'),
        //                                         error: !product
        //                                     }
        //
        //                                     let species = _this.allSpecies.find(s => s.Species.title == row.getCell(4).value)
        //                                     bundle['species'] = {
        //                                         value: species ? species.Species.id : null,
        //                                         text: species ? species.Species.title : _this.$t('message.empty'),
        //                                         error: !species
        //                                     }
        //
        //                                     let certification = _this.allCertifications.find(c => c.Certification.title == row.getCell(5).value)
        //                                     bundle['certification'] = {
        //                                         value: certification ? certification.Certification.id : null,
        //                                         text: certification ? certification.Certification.title : _this.$t('message.empty'),
        //                                         error: !certification
        //                                     }
        //
        //                                     let grade = _this.allGrades.find(g => g.Grade.title == row.getCell(6).value)
        //                                     bundle['grade'] = {
        //                                         value: grade ? grade.Grade.id : null,
        //                                         text: grade ? grade.Grade.title : _this.$t('message.empty'),
        //                                         error: !grade
        //                                     }
        //
        //                                     let spec = _this.allCleanedSpecs.find(s => s.Spec.title == row.getCell(7).value)
        //                                     bundle['spec'] = {
        //                                         value: spec ? spec.Spec.id : row.getCell(7),
        //                                         text: spec ? spec.Spec.title : row.getCell(7),
        //                                         error: spec ? false : (row.getCell(7).value == null)
        //                                     }
        //
        //                                     let mc = _this.allMoistureContent.find(c => c.Mc.title == row.getCell(8).value)
        //                                     bundle['mc'] = {
        //                                         value: mc ? mc.Mc.id : null,
        //                                         text: mc ? mc.Mc.title : _this.$t('message.empty'),
        //                                         error: !mc
        //                                     }
        //
        //                                     let thickness = _this.allThickness.find(t => t.Thickness.title == row.getCell(9).value)
        //                                     bundle['thickness'] = {
        //                                         value: thickness ? thickness.Thickness.id : null,
        //                                         text: thickness ? thickness.Thickness.title : _this.$t('message.empty'),
        //                                         error: !thickness
        //                                     }
        //
        //                                     bundle['width'] = {
        //                                         value: row.getCell(10).value,
        //                                         text: row.getCell(10).value ? row.getCell(10).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(10).value,
        //                                     }
        //
        //                                     bundle['length'] = {
        //                                         value: row.getCell(11).value,
        //                                         text: row.getCell(11).value ? row.getCell(11).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(11).value,
        //                                     }
        //
        //                                     bundle['pcs'] = {
        //                                         value: row.getCell(12).value,
        //                                         text: row.getCell(12).value ? row.getCell(12).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(12).value,
        //                                     }
        //
        //                                     bundle['quantity'] = {
        //                                         value: row.getCell(13).value,
        //                                         text: row.getCell(13).value ? row.getCell(13).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(13).value,
        //                                     }
        //
        //                                     let quantityUnit = _this.uofmQty.find(u => u.Measurement.title == row.getCell(14).value)
        //                                     bundle['quantityUnit'] = {
        //                                         value: quantityUnit ? quantityUnit.Measurement.id : null,
        //                                         text: quantityUnit ? quantityUnit.Measurement.title : _this.$t('message.empty'),
        //                                         error: !quantityUnit
        //                                     }
        //
        //                                     bundle['buyingPrice'] = {
        //                                         value: row.getCell(15).value,
        //                                         text: row.getCell(15).value ? row.getCell(15).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(15).value,
        //                                     }
        //
        //                                     bundle['freightCost'] = {
        //                                         value: row.getCell(16).value,
        //                                         text: row.getCell(16).value ? row.getCell(16).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(16).value,
        //                                     }
        //
        //                                     bundle['importDuty'] = {
        //                                         value: row.getCell(17).value,
        //                                         text: row.getCell(17).value ? row.getCell(17).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(17).value,
        //                                     }
        //
        //                                     bundle['storageCost'] = {
        //                                         value: row.getCell(18).value,
        //                                         text: row.getCell(18).value ? row.getCell(18).value : _this.$t('message.empty'),
        //                                         error: !row.getCell(18).value,
        //                                     }
        //
        //                                     if (row.getCell(2).value != null && row.getCell(2).value !== '') {
        //                                         _this.excelData.push(bundle)
        //                                     }
        //                                 }
        //                             });
        //
        //                         } catch (err) {
        //                             _this.$toast.error(err,
        //                                 {
        //                                     classes: ['icon-float-left'],
        //                                     icon: 'error_outline'
        //                                 }
        //                             )
        //                         }
        //                         _this.uploadingFile = false
        //                     })
        //             } catch (err) {
        //                 _this.$toast.error(err,
        //                     {
        //                         classes: ['icon-float-left'],
        //                         icon: 'error_outline'
        //                     }
        //                 )
        //                 _this.uploadingFile = false
        //             }
        //         }
        //     } catch (err) {
        //         _this.$toast.error(err,
        //             {
        //                 classes: ['icon-float-left'],
        //                 icon: 'error_outline'
        //             }
        //         )
        //         _this.uploadingFile = false
        //     }
        // }
        readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file)
                reader.onload = () => {
                    resolve(reader.result)
                }
            })
        }
    },
    watch: {
        dialog(value) {
            this.upload_dialog = value
        }
    },
    created() {
        if(this.allSpecies.length === 0) this.getAllSpecies()
        if(this.allGrades.length === 0) this.getAllGrades()
        if(this.allCertifications.length === 0) this.getAllCertifications()
        if(this.allMoistureContent.length === 0) this.getAllMoistureContent()
        if(this.allProductGroups.length === 0) this.getAllProductGroups()
        if(this.allCleanedSpecs.length === 0) this.getAllCleanedSpecs()
        if(this.allThickness.length === 0) this.getAllThickness()
    }
}
</script>

<style scoped>
::v-deep .v-dialog {
    width: unset;
    max-width: unset !important;
}

.v-data-table th {
    font-size: 1em !important;
}

.v-data-table td {
    font-size: 1em !important;
}
</style>